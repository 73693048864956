import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/workspace/src/components/MdxLayout.tsx";
import MdxCard from 'src/components/MdxCard';
import SEO from 'src/components/SEO';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Install Eva Icons" mdxType="SEO" />
    <MdxCard mdxType="MdxCard">
      <h2 {...{
        "id": "eva-icons"
      }}>{`Eva Icons`}</h2>
      <p>{`In case you need to have more icons available, Pal.js UI provides a `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`@paljs/icons`}</code>{` pack with 480+ SVG icons.`}</p>
      <p>{`Install the pack:`}</p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "bash"
      }}><pre parentName="div" {...{
          "className": "language-bash"
        }}><code parentName="pre" {...{
            "className": "language-bash"
          }}><span parentName="code" {...{
              "className": "token function"
            }}>{`yarn`}</span>{` `}<span parentName="code" {...{
              "className": "token function"
            }}>{`add`}</span>{` @paljs/icons`}</code></pre></div>
      <p>{`Or`}</p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "bash"
      }}><pre parentName="div" {...{
          "className": "language-bash"
        }}><code parentName="pre" {...{
            "className": "language-bash"
          }}><span parentName="code" {...{
              "className": "token function"
            }}>{`npm`}</span>{` i @paljs/icons`}</code></pre></div>
      <p>{`In your Layout page add icons to Layout component`}</p>
      <div {...{
        "className": "gatsby-highlight has-highlighted-lines",
        "data-language": "jsx"
      }}><pre parentName="div" {...{
          "className": "language-jsx"
        }}><code parentName="pre" {...{
            "className": "language-jsx"
          }}><span parentName="code" {...{
              "className": "token keyword"
            }}>{`import`}</span>{` icons `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`from`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'@paljs/icons'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`

`}<span parentName="code" {...{
              "className": "token comment"
            }}>{`//...`}</span>{`
`}<span parentName="code" {...{
              "className": "gatsby-highlight-code-line"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token tag"
                }}><span parentName="span" {...{
                    "className": "token punctuation"
                  }}>{`<`}</span><span parentName="span" {...{
                    "className": "token class-name"
                  }}>{`Layout`}</span></span>{` `}<span parentName="span" {...{
                  "className": "token attr-name"
                }}>{`windowMode`}</span>{` `}<span parentName="span" {...{
                  "className": "token attr-name"
                }}>{`evaIcons`}</span><span parentName="span" {...{
                  "className": "token script language-javascript"
                }}><span parentName="span" {...{
                    "className": "token script-punctuation punctuation"
                  }}>{`=`}</span><span parentName="span" {...{
                    "className": "token punctuation"
                  }}>{`{`}</span>{`icons`}<span parentName="span" {...{
                    "className": "token punctuation"
                  }}>{`}`}</span></span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`>`}</span></span><span parentName="span" {...{
                "className": "token plain-text"
              }}></span></span><span parentName="code" {...{
              "className": "token plain-text"
            }}>{`  `}</span><span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span><span parentName="span" {...{
                  "className": "token class-name"
                }}>{`Header`}</span></span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`changeTheme`}</span><span parentName="span" {...{
                "className": "token script language-javascript"
              }}><span parentName="span" {...{
                  "className": "token script-punctuation punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`changeTheme`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`}`}</span></span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`toggleSidebar`}</span><span parentName="span" {...{
                "className": "token script language-javascript"
              }}><span parentName="span" {...{
                  "className": "token script-punctuation punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`{`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`)`}</span>{` `}<span parentName="span" {...{
                  "className": "token operator"
                }}>{`=>`}</span>{` sidebarRef`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`.`}</span>{`current`}<span parentName="span" {...{
                  "className": "token operator"
                }}>{`?.`}</span><span parentName="span" {...{
                  "className": "token function"
                }}>{`toggle`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`)`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`}`}</span></span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`/>`}</span></span><span parentName="code" {...{
              "className": "token plain-text"
            }}></span>{`
`}<span parentName="code" {...{
              "className": "token plain-text"
            }}>{`  `}</span><span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span><span parentName="span" {...{
                  "className": "token class-name"
                }}>{`LayoutContainer`}</span></span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span><span parentName="code" {...{
              "className": "token plain-text"
            }}></span>{`
`}<span parentName="code" {...{
              "className": "token plain-text"
            }}>{`    `}</span><span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span><span parentName="span" {...{
                  "className": "token class-name"
                }}>{`SidebarCustom`}</span></span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`ref`}</span><span parentName="span" {...{
                "className": "token script language-javascript"
              }}><span parentName="span" {...{
                  "className": "token script-punctuation punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`sidebarRef`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`}`}</span></span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`/>`}</span></span><span parentName="code" {...{
              "className": "token plain-text"
            }}></span>{`
`}<span parentName="code" {...{
              "className": "token plain-text"
            }}>{`    `}</span><span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span><span parentName="span" {...{
                  "className": "token class-name"
                }}>{`LayoutContent`}</span></span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span><span parentName="code" {...{
              "className": "token plain-text"
            }}></span>{`
`}<span parentName="code" {...{
              "className": "token plain-text"
            }}>{`      `}</span><span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span><span parentName="span" {...{
                  "className": "token class-name"
                }}>{`LayoutColumns`}</span></span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span><span parentName="code" {...{
              "className": "token plain-text"
            }}></span>{`
`}<span parentName="code" {...{
              "className": "token plain-text"
            }}>{`        `}</span><span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span><span parentName="span" {...{
                  "className": "token class-name"
                }}>{`LayoutColumn`}</span></span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`className`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation attr-equals"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`main-content`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`props`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`children`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span><span parentName="span" {...{
                  "className": "token class-name"
                }}>{`LayoutColumn`}</span></span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span><span parentName="code" {...{
              "className": "token plain-text"
            }}></span>{`
`}<span parentName="code" {...{
              "className": "token plain-text"
            }}>{`      `}</span><span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span><span parentName="span" {...{
                  "className": "token class-name"
                }}>{`LayoutColumns`}</span></span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span><span parentName="code" {...{
              "className": "token plain-text"
            }}></span>{`
`}<span parentName="code" {...{
              "className": "token plain-text"
            }}>{`      `}</span><span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span><span parentName="span" {...{
                  "className": "token class-name"
                }}>{`LayoutFooter`}</span></span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span><span parentName="code" {...{
              "className": "token plain-text"
            }}>{`Footer`}</span><span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span><span parentName="span" {...{
                  "className": "token class-name"
                }}>{`LayoutFooter`}</span></span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span><span parentName="code" {...{
              "className": "token plain-text"
            }}></span>{`
`}<span parentName="code" {...{
              "className": "token plain-text"
            }}>{`    `}</span><span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span><span parentName="span" {...{
                  "className": "token class-name"
                }}>{`LayoutContent`}</span></span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span><span parentName="code" {...{
              "className": "token plain-text"
            }}></span>{`
`}<span parentName="code" {...{
              "className": "token plain-text"
            }}>{`  `}</span><span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span><span parentName="span" {...{
                  "className": "token class-name"
                }}>{`LayoutContainer`}</span></span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span><span parentName="code" {...{
              "className": "token plain-text"
            }}></span>{`
`}<span parentName="code" {...{
              "className": "token plain-text"
            }}></span><span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span><span parentName="span" {...{
                  "className": "token class-name"
                }}>{`Layout`}</span></span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
`}<span parentName="code" {...{
              "className": "token comment"
            }}>{`//...`}</span></code></pre></div>
    </MdxCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      